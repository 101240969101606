
header.header {
  height: 90px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

header.header .header-container {
  height: 100%;
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: row;
}

header.header .header-container .icon-container {
  height: 100%;
  width: 200px;
  background-image: url("../images/Sink-Mount-Logo-1.png");
  background-repeat: no-repeat;
  background-size: contain;
}

header.header .header-container .burger-container {
  display: none;
  flex: 7;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

header.header .header-container .menu-container {
  height: 100%;
  flex: 7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.menu-container .menu-item {
  color: #444444;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
  cursor: pointer;
}

.menu-container .sub-menu-container {
  color: #444444;
  flex-direction: column;  
  position: absolute;
  top: 90px;
  background-color: white;
  width: 270px;
  padding: 15px;
  border-top-color: #007cba;
  border-top-width: 2px;
  border-top-style: solid;
}

.menu-container .sub-menu-container a {
  text-decoration: none;
  color: #444444;
  margin-top: 10px;
  margin-bottom: 10px;
}

.burger-menu  {
  position: absolute;
  background-color: white;
  top: 90px;
  left: 0;
  width: 100%;
  z-index: 999;
}

.burger-menu .menu-item {
  color: #444444;
  height: 100%;
  display: flex;
  align-items: center;
  //justify-content: center;
  font-family: "Open Sans";
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 1em;
  padding-left: 1.2em;
  border-color: lightgrey;
  border-style: solid;
  border-width: 0;  
  border-bottom-width: 1px;
}

.burger-menu .menu-item.search span{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: lightgrey;
}

.burger-menu .menu-item.search span input {
  border: none;
  padding: 1em;
  flex: 9;
}

.burger-menu .menu-item.search span i{
  font-size: 1.5em;
  padding: 0.6em;
  flex: 1;
  text-align: center;
  color: lightgrey;
}

.burger-menu .menu-item.search:hover{
  background-color: white;
}

.burger-menu .menu-item.search span:hover{
  background-color: white;
}

.burger-menu .menu-item.search span i:hover{
  background-color: white;
}

.burger-menu .menu-item:hover {
  background-color: #eeeeee;
}

.burger-menu .menu-item.more-resources{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 1120px), screen and (max-device-width: 1120px) {
  .burger-menu .sub-menu-container {
    display: flex;
    flex-direction: column;
  }

  .burger-menu .sub-menu-container a {
    font-size: 0.75em;
    padding: 1em;
    color: black;
    text-decoration: none;
  }

  .burger-menu .sub-menu-container a:hover {
    background-color: #eeeeee;
  }
}




@media screen and (max-width: 1120px), screen and (max-device-width: 1120px) {
  header.header .header-container .menu-container {
    display: none;
  }

  header.header .header-container .burger-container {
    display: flex;
  }

  header.header .header-container .burger-container i {
    cursor: pointer;
    font-size: 1.5em;
    color: #444444;
    padding-left: 0.75em;
    padding-right: 0.75em;
  }
}

.image-container {
  width: 94%;
  height: 50vw;
  max-height: 400px;
  align-self: center;
  max-width: 1100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-color: lightgray;
  border-style: solid;
  padding: 0.5em;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-title-buy-container {  
  width: 94%;
  align-self: center;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  font-family: "Open Sans";
}

.product-title-buy-container .title-price {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.product-title-buy-container .title-price .title {
  font-family: "Open Sans Condensed";
  text-transform: uppercase;  
  font-weight: 600;
  font-size: 1.7em;
}

.product-title-buy-container .title-price .price {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 1.7em;
  color: grey;
  margin-left: 0.2em;
}

.learn-more-add-to-cart {
  width: 100%;
  max-width: 20em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 1.25em;
  margin-bottom: 1em;
  color: #777777;
}  

@media screen and (max-width: 1120px), screen and (max-device-width: 1120px) {
  .learn-more-add-to-cart {
    max-width: 38em;
  }  
}

.learn-more-add-to-cart .learn-more {
  color: grey;
  text-decoration: none;
  font-weight: 600;
}

.learn-more-add-to-cart .add-to-cart i {
  margin-right: 0.25em;
}

.learn-more-add-to-cart .add-to-cart {
  color: white;
  background-color: #476bd8;
  padding: 0.75em;
  text-transform: uppercase;
  cursor: pointer;
}

.customer-reviews {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1.5em;
  
}
.customer-reviews span {
  position: relative;
  color: #777777;
  margin-left: calc(50% - 2.5em);
}
.customer-reviews span:before {
  content: "\2605\2605\2605\2605\2605";
  color: #777777;
  float: left;
  top: 0;
  left: -5em;
  position: absolute;
}

.product-description-container {
  width: 94%;
  align-self: center;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  font-family: "Open Sans";
  padding-bottom: 2em;
}

.product-description-container .title {
  width: 100%;
  text-align: left;
  font-family: "Open Sans Condensed";
  text-transform: uppercase;  
  font-weight: 600;
  font-size: 1.4em;
}

.product-description-container .description {
  color: #777777;
}

.product-description-container .checkboxes {
  color: #777777; 
}

footer.footer {
  position: relative;
  background-color: #3d4045;
  display: flex;
  flex-direction: row;
  
  justify-content: center;
  font-family: "Open Sans";
  padding-top: 1em;
}

@media screen and (max-width: 1120px), screen and (max-device-width: 1120px) {
  footer.footer {
    flex-direction: column;
    align-items: center;
  }
}

footer.footer .footer-container {
  width: 50%;
  max-width: 230px;
  display: flex;
  flex-direction: column;
  padding-top: 2.5em;
  margin-bottom: 5em;
  padding-bottom: 5em;
  margin-left: 1.2em;
  margin-right: 1.2em;
}

footer.footer .footer-container.sitemap {
  display: flex;
}

@media screen and (max-width: 1120px), screen and (max-device-width: 1120px) {
  footer.footer .footer-container.sitemap {
    display: flex;
  }
  footer.footer .footer-container {
    width: 90%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }  
}

footer.footer .header {
  color: white;
  width: 100%;
  text-align: left;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.9em;
  margin-bottom: 0.75em;
}

footer.footer a {
  margin-top: 0.45em;
  margin-bottom: 0.45em;
  text-decoration: none;
  color: #999999;
  font-size: 0.9em;
}

@media screen and (max-width: 1120px), screen and (max-device-width: 1120px) {
  footer.footer .header {
    color: white;
    width: 100%;
    text-align: left;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.2em;
    margin-bottom: 1em;
  }

  footer.footer a {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    text-decoration: none;
    color: #999999;
    font-size: 0.9em;
  }
}

footer.footer .img-container {
  width: 25%;
  max-width: 300px;
  margin-top: 2em;
  margin-bottom: 4em;
}

@media screen and (max-width: 1120px), screen and (max-device-width: 1120px) {
  footer.footer .img-container {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 4em;
  }
}

footer.footer .img-container div {
  background-repeat: no-repeat;
}

footer.footer .img-container .row-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

footer.footer .img-container .row-1 div {
  width: 90px;
  height: 60px;
}  

footer.footer .img-container .row-2 div {
  height: 25px;
  width: 105px;
  background-image: url("../images/sw-asia-1.jpg");
  background-size: contain; 
}

footer.footer .img-container .row-3 div {
  height: 60px;
  width: 120px;
  background-image: url("../images/paytraceSeal.png");
  background-size: contain; 
}

footer.footer .img-container .row-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1.75em;
  margin-bottom: 1em;
}



footer.footer .img-container .row-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1.5em;
  margin-bottom: 1em;
}


@media screen and (max-width: 1120px), screen and (max-device-width: 1120px) {
  footer.footer .img-container .row-1 div {
    width: 110px;
    height: 80px;
  }  
  footer.footer .img-container .row-2 div {
    height: 50px;
    width: 140px;
    background-image: url("../images/sw-asia-1.jpg");
    background-size: contain; 
  }

  footer.footer .img-container .row-3 div {
    height: 80px;
    width: 160px;
    background-image: url("../images/paytraceSeal.png");
    background-size: contain; 
  }
}


footer.footer .img-container .row-1 .img1 {
  background-image: url("../images/Made_In_USA-1.png");
  background-size: contain;
}

footer.footer .img-container .row-1 .img2 {
  background-image: url("../images/veteran.png");
  background-size: contain;
}

footer.footer .sub-footer {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  background-color: #43474d;
  padding-top: 1.7em;
  padding-bottom: 1.7em;
  color: #8c8e91;
  font-size: 0.75em;
}


@media screen and (max-width: 1120px), screen and (max-device-width: 1120px) {
  footer.footer .sub-footer {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    background-color: #43474d;
    padding-top: 0.5em;
    padding-bottom: 0;
    color: #8c8e91;
    font-size: 0.9em;
  }  
}

footer.footer .sub-footer .sub-footer-container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

footer.footer .sub-footer span {
  margin-left: 0.25em;  
}